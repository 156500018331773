import React from 'react';
import faqHeroImage from '../assets/images/smokeyguitar.jpg';

function FAQ() {
  return (
    <div className="container my-5">
      <section className="position-relative text-center text-white">
      <img src={faqHeroImage} className="img-fluid w-100" alt="Concert lights" style={{ objectFit: 'cover', height: '60vh' }} />
        <div className="position-absolute top-50 start-50 translate-middle">
          <h1 className="display-3 fw-bold text-white">Frequently Asked Questions</h1>
        </div>
      </section>

      <div className="accordion" id="faqAccordion">
        {/* Accordion Items */}
        {[
          
            {
              id: 1,
              question: "What is Vercee?",
              answer: "Vercee is a premier virtual concert platform designed to deliver immersive, high-quality live music experiences directly to fans’ devices. Our platform is tailored for artists who want to go beyond simple live streams, offering a professional, cinematic experience that combines live music, storytelling, and high production values."
            },
            {
              id: 2,
              question: "How does Vercee work?",
              answer: "Vercee collaborates closely with artists to produce exclusive, full-length performances. Artists bring their creative vision, and Vercee manages everything from production to streaming. Fans can purchase Pay-Per-View (PPV) tickets for individual shows or subscribe for on-demand access to our entire concert library."
            },
            {
              id: 3,
              question: "What are the benefits of performing on Vercee?",
              answer: "Vercee offers artists an all-inclusive production experience at no cost. We handle everything, including venue, set design, technical setup, and live streaming, so artists can focus on their performance. Vercee also provides unique revenue opportunities through ticket sales, merchandise integration, and virtual meet-and-greet sessions, expanding income without impacting physical tour revenue."
            },
            {
              id: 4,
              question: "How do I watch a concert on Vercee?",
              answer: "To watch a concert, simply browse our event listings, choose a show, and purchase a ticket for PPV access. Users can access our full library of live and on-demand concerts. Vercee also includes interactive features like live chat, enhancing the fan experience."
            },
            {
              id: 5,
              question: "What makes Vercee different from other streaming platforms?",
              answer: "Vercee is purpose-built for concert-length, high-quality performances. Unlike other platforms that cater to casual or short-form content, Vercee supports longer, story-driven shows ranging from 45 to 90 minutes. Artists have creative freedom to design unique experiences with multi-camera angles, set designs, and other theatrical elements, making each show feel exclusive and unforgettable."
            },
            {
              id: 6,
              question: "Why should artists choose Vercee for virtual concerts?",
              answer: "Vercee provides a risk-free, high-quality platform tailored for artists who want to connect deeply with fans. We take care of the entire production process, ensuring that artists can deliver a memorable performance without financial risk."
            },
            {
              id: 7,
              question: "Does Vercee provide revenue-sharing opportunities for artists?",
              answer: "Yes, Vercee offers multiple revenue streams for artists, including PPV ticket sales, merchandise integration, and virtual meet-and-greet options. Our revenue-sharing model is designed to maximize income for artists without any production costs on their end."
            },
            {
              id: 8,
              question: "What can fans expect from a Vercee concert experience?",
              answer: "Fans can expect a high-quality, interactive concert experience that feels like a blend of live music, Broadway performances, and cinematic storytelling. With features like real-time chat, exclusive content, and virtual meet-and-greets, Vercee offers a unique, immersive way to enjoy concerts from anywhere."
            },
            {
              id: 9,
              question: "How does Vercee handle artist payouts?",
              answer: "Vercee tracks all revenue generated through PPV tickets, subscriptions, and merchandise sales, and ensures that artists receive their share based on the agreed payout structure. Payments are made transparently and promptly, with detailed breakdowns provided."
            },
            {
              id: 10,
              question: "What if I miss a live concert?",
              answer: "If you miss a live concert, many performances are available as on-demand videos for users. You can enjoy the full concert experience anytime, as part of our on-demand library."
            },
            {
              id: 11,
              question: "Can I watch Vercee concerts on multiple devices?",
              answer: "Yes, Vercee is accessible across various devices, including desktops, laptops, tablets, and smartphones. This allows fans to enjoy the concert experience wherever they are."
            },
            {
              id: 12,
              question: "Does Vercee support international artists and audiences?",
              answer: "Absolutely. Vercee is a global platform that supports artists and fans from around the world. We aim to provide a seamless experience across time zones and locations, bringing diverse music and performances to a global audience."
            },
            {
              id: 13,
              question: "How do I purchase tickets for a Vercee concert?",
              answer: "Simply browse our event listings, select the concert you’re interested in, and purchase a ticket via Pay-Per-View."
            },
            {
              id: 14,
              question: "Can I buy merchandise during the concert?",
              answer: "Yes, Vercee allows fans to purchase merchandise directly through the platform during and after concerts. Artists can link their merchandise stores to make it easy for fans to support them further."
            },
            {
              id: 15,
              question: "Does Vercee offer support for artists’ creative ideas?",
              answer: "Yes, Vercee works closely with artists and their teams to bring their creative vision to life. We support everything from set design and multi-angle views to storytelling elements, ensuring that artists have the creative freedom to produce a truly unique experience."
            },
            {
              id: 16,
              question: "Is Vercee suitable for all genres of music?",
              answer: "Vercee is designed to be flexible and inclusive, supporting a wide range of musical genres. Whether you’re a solo artist, band, or ensemble, Vercee can provide the tools and production support needed to deliver a top-quality performance."
            },
            {
              id: 17,
              question: "What happens if I experience technical issues during a live show?",
              answer: "Our support team is available to help with any technical issues that may arise during a performance. We also provide troubleshooting resources and may offer a replay option for viewers if an issue interrupts your viewing experience."
            },
            {
              id: 18,
              question: "How do artists benefit from virtual concerts on Vercee?",
              answer: "Vercee gives artists a unique opportunity to reach fans globally, without the logistical challenges of a physical tour. Our virtual platform allows artists to engage with fans, earn revenue through various streams, and create memorable experiences without the high costs of traditional tours."
            },
            {
              id: 19,
              question: "Does Vercee integrate with social media?",
              answer: "While Vercee is a standalone platform, artists and fans are encouraged to share performances on social media. This helps increase visibility and creates a community of music lovers who can connect over shared experiences."
            }
          
          
        ].map(({ id, question, answer }) => (
          <div className="accordion-item bg-dark border border-secondary" key={id}>
            <h2 className="accordion-header" id={`heading${id}`}>
              <button className="accordion-button collapsed bg-secondary text-white" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${id}`} aria-expanded="false" aria-controls={`collapse${id}`}>
                {question}
              </button>
            </h2>
            <div id={`collapse${id}`} className="accordion-collapse collapse" aria-labelledby={`heading${id}`} data-bs-parent="#faqAccordion">
              <div className="accordion-body bg-dark text-light">
                {answer}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
